import { DocumentCompletionStatus } from "../Documents/constants";
import { type HcpRequirementStatus } from "../Documents/types";

export const getCountByRequirementStatus = (
  hcpRequirementStatus: HcpRequirementStatus
): Record<string, string | number> => {
  const { completed, pending, expired, rejected, missing, optional, missingRequirementAtHCFLevel } =
    hcpRequirementStatus;
  const total = new Set([
    ...completed,
    ...pending,
    ...expired,
    ...rejected,
    ...missing,
    ...optional,
  ]).size;
  const submitted = new Set([...completed, ...pending]).size;
  const documentsLeft = total - submitted;

  return {
    status: documentsLeft
      ? DocumentCompletionStatus.NOT_COMPLETED
      : DocumentCompletionStatus.COMPLETED,
    count_docs_completed: completed.length ?? 0,
    count_docs_missing: missing.length ?? 0,
    count_docs_optional: optional.length ?? 0,
    count_docs_required_by_some_hcfs: missingRequirementAtHCFLevel.length ?? 0,
    count_docs_expired: expired.length ?? 0,
    count_docs_submitted_pending: pending.length ?? 0,
  };
};
