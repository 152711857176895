/*
  This file and all its exported members are deprecated.
  GraphQL is deprecated in app v2.
  It will be removed when we migrate documents-service-backend from GraphQL to REST API
  This is the Epic to track this work: https://clipboardhealth.atlassian.net/browse/CH-23128
*/

// eslint-disable-next-line no-restricted-imports
import { gql } from "@apollo/client";
// eslint-disable-next-line no-restricted-imports
import { print } from "graphql";

const DEPRECATED_DO_NOT_USE_GRAPHQL_GET_DISTRIBUTION_URL_GQL = gql`
  query GET_DISTRIBUTION_URL($docId: ObjectId!, $shiftId: ObjectId) {
    getDistributionUrl(docId: $docId, shiftId: $shiftId) {
      distributionUrl
    }
  }
`;

const DEPRECATED_DO_NOT_USE_GRAPHQL_GET_PRESIGNED_UPLOAD_URL_GQL = gql`
  query GET_PRESIGNED_UPLOAD_URL($reqId: ObjectId!, $fileType: String!) {
    getPresignedUploadUrl(reqId: $reqId, fileType: $fileType) {
      preSignedUrl
      fileStorageRootFolder
      fileStorageFileKey
    }
  }
`;

const DEPRECATED_DO_NOT_USE_GRAPHQL_GET_REJECTED_DOCUMENT_GQL = gql`
  query GET_REJECTED_DOCUMENT($hcpId: ObjectId!, $requirementId: ObjectId!) {
    rejectedDocument(hcpId: $hcpId, requirementId: $requirementId) {
      notification {
        reason
        pushNotification
        note
      }
    }
  }
`;
const DEPRECATED_DO_NOT_USE_GRAPHQL_GET_FILTERED_DOCUMENTS_GQL = gql`
  query GET_FILTERED_DOCUMENTS(
    $hcpId: ObjectId
    $limit: Int
    $sort: Sort
    $filter: DocumentFilter
  ) {
    documentList(hcpId: $hcpId, limit: $limit, sort: $sort, filter: $filter) {
      _id
      createdAt
      subType
      fileStorageRootFolder
      fileStorageFileKey
    }
  }
`;

const DEPRECATED_DO_NOT_USE_GRAPHQL_GET_HCP_REQUIREMENTS_GQL = gql`
  query GET_HCP_REQUIREMENTS($hcpId: ObjectId!, $filter: RequirementsFilter) {
    hcpRequirementList(hcpId: $hcpId, filter: $filter) {
      expiry
      baseRequirement {
        expiryType
      }
    }
  }
`;

const DEPRECATED_DO_NOT_USE_GRAPHQL_CREATE_HCP_DOCUMENT_GQL = gql`
  mutation CREATE_HCP_DOCUMENT(
    $subType: String
    $fileUrl: String
    $hcpId: ObjectId
    $name: String!
    $uploadedFor: ObjectId!
    $fileStorageRootFolder: String
    $fileStorageFileKey: String
  ) {
    createHcpDocument(
      fileUrl: $fileUrl
      hcpId: $hcpId
      name: $name
      uploadedFor: $uploadedFor
      fileStorageRootFolder: $fileStorageRootFolder
      fileStorageFileKey: $fileStorageFileKey
      subType: $subType
    ) {
      _id
      name
    }
  }
`;

const DEPRECATED_DO_NOT_USE_GRAPHQL_DELETE_HCP_DOCUMENT_GQL = gql`
  mutation DELETE_HCP_DOCUMENT($_id: ObjectId!) {
    deleteHcpDocument(_id: $_id) {
      _id
    }
  }
`;

/**
 * @deprecated This function is deprecated and will be removed when we migrate from GraphQL to REST API.
 * This is the ticket to migrate this endpoint: https://clipboardhealth.atlassian.net/browse/CH-23129
 * FIXME(CH-23129): Remove this endpoint after migrating to REST API
 */
export const DEPRECATED_DO_NOT_USE_GET_DISTRIBUTION_URL = print(
  DEPRECATED_DO_NOT_USE_GRAPHQL_GET_DISTRIBUTION_URL_GQL
);

/**
 * @deprecated This function is deprecated and will be removed when we migrate from GraphQL to REST API.
 * This is the ticket to migrate this endpoint: https://clipboardhealth.atlassian.net/browse/CH-23195
 * FIXME(CH-23195): Remove this endpoint after migrating to REST API
 */
export const DEPRECATED_DO_NOT_USE_GET_PRESIGNED_UPLOAD_URL = print(
  DEPRECATED_DO_NOT_USE_GRAPHQL_GET_PRESIGNED_UPLOAD_URL_GQL
);

/**
 * @deprecated This function is deprecated and will be removed when we migrate from GraphQL to REST API.
 * This is the ticket to migrate this endpoint: https://clipboardhealth.atlassian.net/browse/CH-23233
 * FIXME(CH-23233): Remove this endpoint after migrating to REST API
 */
export const DEPRECATED_DO_NOT_USE_GET_REJECTED_DOCUMENT = print(
  DEPRECATED_DO_NOT_USE_GRAPHQL_GET_REJECTED_DOCUMENT_GQL
);

/**
 * @deprecated This function is deprecated and will be removed when we migrate from GraphQL to REST API.
 * This is the ticket to migrate this endpoint: https://clipboardhealth.atlassian.net/browse/CH-23234
 * FIXME(CH-23234): Remove this endpoint after migrating to REST API
 */
export const DEPRECATED_DO_NOT_USE_GET_FILTERED_DOCUMENTS = print(
  DEPRECATED_DO_NOT_USE_GRAPHQL_GET_FILTERED_DOCUMENTS_GQL
);

/**
 * @deprecated This function is deprecated and will be removed when we migrate from GraphQL to REST API.
 * This is the ticket to migrate this endpoint: https://clipboardhealth.atlassian.net/browse/CH-23235
 * FIXME(CH-23235): Remove this endpoint after migrating to REST API
 */
export const DEPRECATED_DO_NOT_USE_GET_HCP_REQUIREMENTS = print(
  DEPRECATED_DO_NOT_USE_GRAPHQL_GET_HCP_REQUIREMENTS_GQL
);

/**
 * @deprecated This function is deprecated and will be removed when we migrate from GraphQL to REST API.
 * This is the ticket to migrate this endpoint: https://clipboardhealth.atlassian.net/browse/CH-23363
 * FIXME(CH-23363): Remove this endpoint after migrating to REST API
 */
export const DEPRECATED_DO_NOT_USE_GRAPHQL_CREATE_HCP_DOCUMENT = print(
  DEPRECATED_DO_NOT_USE_GRAPHQL_CREATE_HCP_DOCUMENT_GQL
);

/**
 * @deprecated This function is deprecated and will be removed when we migrate from GraphQL to REST API.
 * This is the ticket to migrate this endpoint: https://clipboardhealth.atlassian.net/browse/CH-23364
 * FIXME(CH-23364): Remove this endpoint after migrating to REST API
 */
export const DEPRECATED_DO_NOT_USE_GRAPHQL_DELETE_HCP_DOCUMENT = print(
  DEPRECATED_DO_NOT_USE_GRAPHQL_DELETE_HCP_DOCUMENT_GQL
);
